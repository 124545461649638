import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Image } from 'rebass';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "read-confirmations---administration"
    }}>{`Read Confirmations - Administration`}</h1>
    <br />
    <p>{`In the general administration you can find a list of all read confirmation requests sent. You can filter them by page, sender and recipients.`}</p>
    <Image width={'80%'} src='/public/readconfirmation4.png' sx={{
      borderRadius: 8,
      border: '1px solid lightgrey',
      mb: 4
    }} mdxType="Image" />
    <p>{`You will also be able to customize the notification settings according to your organisation's needs. You can disable workbox and email notifications
(they are enabled by default) as well as change the wording in the email notification template.`}</p>
    <Image width={'80%'} src='/public/readconfirmation5.png' sx={{
      borderRadius: 8,
      border: '1px solid lightgrey',
      mb: 4
    }} mdxType="Image" />
    <p>{`Under Confluence' cache settings, you will also find two caches that the app introduces:`}</p>
    <p>{`tech.realigned.readconfirmationcache`}</p>
    <p>{`as well as the settings cache. You do not need to modify the settings cache settings ever, this cache should be correctly sized for any Confluence instance.`}</p>
    <p>{`If you have a lot of pages, you might want to increase "readconfirmationcache" because it will otherwise only cache 1000 pages, which might affect page view performance.`}</p>
    <p>{`You can disable or enable the Actions button on the top of pages in the administration.
You can also disable the teams functionality if you don't need it. It makes sense to limit teams to a certain number of users to avoid mass-notifications.`}</p>
    <Image width={'80%'} src='/public/rcAdmin.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      